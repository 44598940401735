@import '../../style/global.module.scss';
.containerMenu{
  position: absolute;
  opacity: 0.85;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 10px;
  right: 30px;
  background-color: rgba(0,0,0,0.5);
  padding:  30px;
  
  .label {
    font-family: $Medium-font-family;
    color: $color-white;
    @include adaptiv-font(20, 16);
    font-weight: 600;
    white-space: nowrap;
  }

}