@import '../../style/global.module.scss';

.container{
    height: 450px;
    width: 200px;
    filter: grayscale(100);
    transition: 0.5s;
    overflow: hidden;
    z-index: 9999;
    position: relative;
    border-radius: 30px;

    &:hover{
        height: 500px;
        width: 370px;
        filter: grayscale(0);
        position: relative;
        z-index: 3;
        .boxText{
            z-index: 2;
        }
    }
}
.image{
    height: 100%;
    width: 100%;
    object-fit: cover;
    
}
.boxText{
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    top: -80px;
    z-index: -2;
    width: 100%;
}
.name{
    color: $color-white;
    @include adaptiv-font(24, 20);
    font-weight: 600;
    font-family: $Medium-font-family-Italic;
}
.position{
    color: $color-white;
    @include adaptiv-font(14, 12);
    font-weight: 400;
    font-family: $Light-font-family-Italic;
}
@media (min-width: 2300px) {
    .container{
        height: 700px;
        width: 500px;
        &:hover{
            height: 800px;
            width: 600px;
        }
    }

}

@media (min-width: 3000px) {
    .container{
        height: 900px;
        width: 600px;
        &:hover{
            height: 1000px;
            width: 700px;
        }
    }
}

@media(max-width: 1100px){
    .container{
        height: 380px;
        width: 150px;
        &:hover{
            height: 440px;
            width: 280px;
            filter: grayscale(0);
            position: relative;
            z-index: 1;
        }
    }

}

@media(max-width: 860px){
    .container{
        height: 300px;
        width: 181px;
        &:hover{
            height: 380px;
            width: 219px;
            filter: grayscale(0);
            position: relative;
            z-index: 1;
        }
    }

}
@media(max-width: 550px){
    .container{
        height: 250px;
        width: 100px;
        &:hover{
            height: 330px;
            width: 140px;
            filter: grayscale(0);
            position: relative;
            z-index: 1;
        }
    }

}
@media(max-width: 450px){
    .container{
        height: 350px;
        width: 110px;
        display: flex;
        flex-wrap: wrap;
        &:hover{
            height: 390px;
            width: 135px;
            filter: grayscale(0);
            position: relative;
            z-index: 1;
        }
    }

}
@media(max-height:600px ){
    .container{
        height: 280px;
        display: flex;
        flex-wrap: wrap;
        &:hover{
            height: 300px;
            width: 260px;
            filter: grayscale(0);
            position: relative;
            z-index: 1;
        }
    }
  }

  @media(max-height:614px ){
    .container{
        height: 230px;
        display: flex;
        flex-wrap: wrap;
        &:hover{
            height: 270px;
            width: 190px;
            filter: grayscale(0);
            position: relative;
            z-index: 1;
        }
    }
  }
  @media(max-height:485px ){
    .container{
        height: 200px;
        display: flex;
        flex-wrap: wrap;
        &:hover{
            height: 230px;
            width: 180px;
            filter: grayscale(0);
            position: relative;
            z-index: 1;
        }
    }
  }