@import '../../../style/global.module.scss';

.container {
  position: absolute;
  width: 100vw;
  height: 100vh;

  .content {
    background-color: transparent;
    position: absolute;
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;

    .boxText {
      padding-left: 70px;
      .headerText {
        color: $color-white;
        @include adaptiv-font(100, 45);
        padding-top: 80px;
        .orangeText {
          color: $color-orange;
          @include adaptiv-font(100, 45);
        }
      }

      .mainText {
        color: $color-white;
        @include adaptiv-font(28, 17);
        padding: 20px 20px 71px 0;
      }
    }
    .boxProjects {
      display: flex;
      flex: 1;
      align-items: center;
      padding-left: 70px;
      overflow: visible;
      gap: 30px;
      animation: slide-animation 50s  linear infinite;
      // &::-webkit-scrollbar {
      //   display: none;
      // }
      .image{
        height: 404px;
        width: 750px;
      }
    }
    .boxAbout{
        display: flex;
        justify-content: flex-end;
        padding-right: 101px;
        padding-top: 20px;

      .textAbout{
        width: 600px;
        color: $color-white;
        @include adaptiv-font(18, 12);
      }
    }
  }
}
@media (min-width: 2300px) {
  .container{
    .content{
      .boxProjects{
        padding-left: 30px;
        .image{
          height: 600px;
          width: 950px;
        }
      }
      .boxAbout{
        padding-right: 30px;
      .textAbout{
        @include adaptiv-font(20, 15);
      }
    }
    }
  }

}
@media (min-width: 3000px) {
}

@media(max-width:500px){
  .container{
    .content{
      .boxText{
        padding-left: 30px;
      }
      .boxProjects{
        padding-left: 30px;
        .image{
          height: 300px;
          width: 550px;
        }
      }
      .boxAbout{
        padding-right: 30px;
      .textAbout{
        width: 300px;
      
      }
    }
    }
  }
}

@media (max-width:376px) {
  .container{
    .content{
      .boxProjects{
        .image{
          height: 300px;
        }
      }
    }
}
}
@media(max-width:1025px){
  .container{
    .content{
      .boxText{
        padding-left: 30px;
      }
      .boxProjects{
        padding-left: 30px;
        .image{
          height: 300px;
          width: 450px;
        }
      }
    }
  }
}
@media(max-height:900px){
  .container{
    .content{
      .boxText{
        padding-left: 30px;
        .headerText {
          padding-top: 40px;
        }
        .mainText{
          padding: 20px 20px 20px 0;
        }
      }
      .boxProjects{
        padding-left: 30px;
        .image{
          height: 300px;
          width: 550px;
        }
      }

    }
  }
}

@media(max-height:700px){
  .container{
    .content{
      .boxText{
        padding-left: 30px;
        .headerText {
          padding-top: 10px;
          font-size: 40px;
        }
      }
      .boxProjects{
        padding-left: 30px;
        .image{
          height: 200px;
          width: 300px;
        }
      }

    }
  }
}
@media(max-height:500px){
  .container{
    .content{
      .boxText{
        padding-left: 30px;
        .headerText {
          padding-top: 10px;
          font-size: 40px;
        }
      }
      .boxProjects{
        padding-left: 30px;
        .image{
          height: 100px;
          width: 200px;
        }
      }
      .boxAbout{
        .textAbout{
          width: 1035px;
        }
      }
    }
  }
}

@keyframes slide-animation {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-800px * 7));
  }
}
