@import '../../style/global.module.scss';

$background-color:#141414;

.container{
filter: drop-shadow(0px 0px 10px rgba(20, 20, 20, 0));
height: 500px;
min-width: 300px;
display: flex;
flex-direction: column;
justify-content:space-between;
margin-right: 30px;
overflow: hidden;
border-radius: 30px;
transition: 0.5s;

position: relative;
.image{
    position: absolute;
    z-index: -1;
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 30px;
    -webkit-filter: brightness(80%);
    filter: brightness(80%);
    
}
.headerText{
    font-family: $Medium-font-family;
    color:$color-white;
    @include adaptiv-font(24, 20);
    font-weight: 600;
    padding: 30px 30px 30px 30px;
    white-space: normal;
    text-transform: uppercase;
}

.boxBody{
    display: flex;
    flex-direction: column;
    padding: 0 30px;
    position: relative;
    z-index: -1;

}
&:hover{
    height: 550px;
}
}


.text {
    color:$color-white;
    font-family: $Light-font-family;
    width: 100%;
    margin-right: 30px ;
    @include adaptiv-font(30, 18);
    font-weight: 500;
    

}
.button{
    font-family: $Medium-font-family;
    color:$color-white;
    @include adaptiv-font(20, 15);
    font-weight: 600;
    background: none;
    border: none;
    cursor: none;
    margin-bottom: 30px ;
   margin-top: 30px;
    text-decoration: none;
    cursor: pointer;

}
.boxButton{
    display:flex;
    flex-direction: row;
   
}
.bodyHeader{
    font-family: $Medium-font-family;
    color:$color-white;
    @include adaptiv-font(24, 17);
    font-weight: 600;
    white-space: normal;
    padding-bottom: 10px;
    text-transform: uppercase;
 
}


// @media(max-width: 1100px){
//     .container{
//         height: 453px;
//         min-width: 133px;
//     }
//     .image{
//         width: 100%;
//         margin: -425px 0 0 0;
//     }
//     .text {
//         width: 301px;
//         margin-right: 30px ;
//         margin-left: 20px;
//     }
//     .boxImage{
//         height: 192px;
//         }

// }
@media (min-width: 2500px) {
    .container{
        height: 800px;
        min-width: 500px;
        &:hover{
            height: 900px;
        }
    }
}
@media (min-width: 3000px) {
    .container{
        height: 1000px;
        min-width: 700px;
        &:hover{
            height: 1100px;
        }
    }
}
@media(max-width: 500px){
    .container{
        height: 450px;
        min-width: 40px;
        &:hover{
            height: 500px;
        }
    }

}
@media(max-height: 660px){
    .container{
        height: 490px;
        }
        .text {
            @include adaptiv-font-height(12, 8);
        }
  }
@media(max-height: 550px){
    .container{
        height: 400px;
        }
        .boxImage{
            height: 100px;
            }
  }
  @media(max-height: 500px){
    .container{
        max-height: 250px;
        }
        .boxImage{
            height: 75px;
            }
            .headerText{
                padding: 5px 0 5px 30px;
            }
  }
  @media(max-height: 370px){
    .container{
        max-height: 160px;
        }
        .boxImage{
            height: 0px;
            }
            .headerText{
                padding: 5px 0 5px 30px;
            }
  }
  