@import 'style/global.module.scss';

.balls {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 36px;
  justify-content: center;
  overflow: hidden;
  position: fixed;
  z-index: 9; 
  background-color:$color-background;
    div {
      width: 3em;
      height: 3em;
      border-radius: 50%;
      background-color: $color-orange;
      transform: translateY(-100%);
      animation: wave 0.8s ease-in-out alternate infinite;
  
      &:nth-of-type(1) {
        animation-delay: -0.9s;
      }

      &:nth-of-type(2) {
        animation-delay: -0.8s;
      }
      &:nth-of-type(3) {
        animation-delay: -0.6s;
      }
      &:nth-of-type(4) {
        animation-delay: -0.4s;
      }
      &:nth-of-type(5) {
        animation-delay: -0.2s;
      }
    }
  }
  
  @keyframes wave {
    from {
      transform: translateY(-100%);
    }
    to {
      transform: translateY(100%);
    }
  }