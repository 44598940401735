@import 'style/global.module.scss';


.container{
    position: relative;
    width: 100vw;
    height: 30vh;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    z-index: 3;

      .content{
        top: 100vh;
        opacity: 0.95;
        padding: 0  70px 0 70px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        z-index: 1;
        .image{
            width: 100%;
        }
        .companies{
            display: flex;
            justify-content: space-between;
            align-items:center
        }
        .imageBox{
            max-width: 8%;
        }
    }
  }

  @media(max-width: 820px){
    .container{

      .companies{
        flex-wrap: wrap;
        .imageBox{
          max-width: 23%;
        }
      }
    }
  }
  @media(max-width: 550px){
    .container{
      .content{
          padding: 20px 20px;
        .companies{
          flex-wrap: wrap;
        }
        .imageBox{
          max-width: 21%;
        }
      }
    }
  }