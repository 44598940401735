.boxImages{
    display: flex;
    flex: 1;
    align-items: center;
    overflow: visible;
    gap: 30px;
  
    animation: slide-animation 60s linear infinite;
    .image{
      
      height: 80vh;
      width: 80vw;
    }
  }
  @media (max-width: 570px) {
    .boxImages{
      .image{
        height: 350px;
        width: 500px;
      }
    }
  }
  @keyframes slide-animation {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-800px * 7));
    }
  }