@import '../../../style/global.module.scss';

.container {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  z-index: 1;
  .svgPoligon {
    position: absolute;
  }
  .circleContainer {
    position: absolute;
    z-index: 0;
    width: 100vw;
    height: 100vh;
    background: $orange-gradient;
    opacity: 0;
  }
  .content {
    // position: absolute;
    width: 100vw;
    height: 100vh;
    top: 100vh;
    opacity: 0.95;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    background-image: $orange-gradient;
  }

  &.show {
    .circleContainer {
      animation-duration: #{$duration}s;
      animation-timing-function: $animation-timing-function-show;
      animation-name: animationBackgroundShow;
      animation-fill-mode: forwards;
    }
    .content {
      animation-duration: #{$duration}s;
      animation-timing-function: $animation-timing-function-show;
      animation-name: animationContentShow;
      animation-fill-mode: forwards;
    }
  }
  &.end {
    .circleContainer {
      opacity: 1;
    }
    .content {
      top: 0px;
      animation-duration: #{$duration}s;
      animation-timing-function: $animation-timing-function-show;
      animation-name: evenEndAnimationText;
      animation-fill-mode: forwards;
    }
  }
}

.maskText {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  top: -20px;
  height: 100vh;
  font-weight: 700;
  @include adaptiv-font(200, 50);
  line-height: 200%;
  text-align: center;
}
.boxImage {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .text2 {
    z-index: 0;
    color: white;
    @include adaptiv-font(200, 40);
  }
  .imageContainer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    transition: all 1s ease;
    z-index: 1;
    .controlContainer {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;
      .playButton {
        height: 75px;
        width: 75px;
        transition: all 0.25s ease;
        cursor: pointer;
        opacity: 1;
        &:active {
          transform: scale(0.9);
        }
        &.open {
          opacity: 0;
        }
      }
      &.open {
        cursor: pointer;
      }
    }
    .video {
      width: 100vw;
      height: 100vh;
      object-fit: cover;
    }
    .text1 {
      z-index: 1;
      color: black;
      opacity: 1;
      @include adaptiv-font(200, 40);
      transition: opacity 1s ease;
      &.open {
        opacity: 0;
      }
    }
  }
}

@keyframes evenEndAnimationText {
  from {
    top: 0px;
    opacity: 1;
  }
  to {
    top: -200px;
    opacity: 0;
  }
}

@keyframes animationContentShow {
  from {
    top: 100vh;
    opacity: 0;
  }
  to {
    top: 0px;
    opacity: 1;
  }
}

@keyframes animationBackgroundShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
