@import 'style/global.module.scss';
.container {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  // background-color: $color-background;
  .innerContainer {
    position: absolute;
    z-index: 0;
    // background-color: $color-background;
    border-radius: 100%;
    height: 0px;
    width: 0px;
  }
  .content {
    top: 100vh;
    opacity: 0.95;
    position: absolute;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    .textContainer {
    position: absolute;
    width: 50%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 70px;
    justify-content: center;
    z-index: 2;
    letter-spacing: 1.5px;
      .about {
        font-family: $Medium-font-family;
        color: $color-white;
        font-weight: 600;
        padding-bottom: 101px;
        @include adaptiv-font(70,29)
      }
      .textAbout {
        font-family: $Light-font-family-Italic;
        color: $color-white;
        font-weight: 400;
        @include adaptiv-font(70, 30);
        flex-wrap: nowrap;
      }
      .orangeText {
        color: $color-orange;
        font-weight: 600;
        margin:0 8px
      }
    }
    .image {
      height: calc(100vh - 30%);
      transition: all 4s ease-out;

    }
  }
  &.show {
    .innerContainer {
      animation-name: $animationContainerShow;
      animation-duration: #{$duration}s;
      animation-timing-function: $animation-timing-function-show;
      animation-fill-mode: forwards;
    }
    .content {
      // animation-delay: #{$contentDuration}s;
      animation-duration: #{$contentDuration}s;
      animation-timing-function: $animation-timing-function-show;
      animation-name: $animationContentShow;
      animation-fill-mode: forwards;
    }
  }
}
@media(max-width:800px){
  .container {
    .content {
      .textContainer {
        width: auto;
        padding-left: 40px;
      }
    }
  }
}

@keyframes imageAnimation {
  0% {
    transform: rotate(0deg);
    top: 0;
    left: 0;
  }
  50% {
    transform: rotate(-5deg);
    top: 20px;
    left: -20px;
  }
  100% {
    transform: rotate(0deg);
    top: 0;
    left: 0;
  }
}

@media(max-height:380px ){
  .container {
    .content {
      .textContainer {
        .about {
          font-family: $Medium-font-family;
          color: $color-white;
          font-weight: 600;
          padding-bottom: 30px;
          @include adaptiv-font-height(32,29)
        }
        .textAbout {
          font-family: $Light-font-family-Italic;
          color: $color-white;
          font-weight: 400;
          @include adaptiv-font-height(32, 27);
          flex-wrap: nowrap;
        }
      }
    }
  }
}