@import './fonts.scss';

.routerContainer {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
}

.slideContainer {
  width: 100vw;
  height: 100vh;
}

.slidersMainContainer {
  overflow: hidden;
  overflow-y: auto;
  overflow-x: hidden;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  flex: 1;
  flex-direction: column;
}

@keyframes animationContainerShow {
  from {
    height: 0px;
    width: 0px;
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    height: 250vw;
    width: 250vw;
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes animationContainerHide {
  0% {
    height: 200vw;
    width: 200vw;
  }
  100% {
    height: 0px;
    width: 0px;
  }
}

@keyframes animationContentHide {
  from {
    top: 0px;
    opacity: 1;
  }
  to {
    top: 100vh;
    opacity: 0.95;
  }
}

@keyframes animationContentShow {
  from {
    top: 100vh;
    transform: scale(0.8);
    opacity: 0;
  }
  to {
    top: 0px;
    transform: scale(1);
    opacity: 1;
  }
}
$duration: 0.25;
$contentDuration: 1.5;
$color-orange: #f4a01a;
$color-background: #1e1e1e;
$orange-gradient: linear-gradient(90deg, #f4a01a 0%, #ba4734 124.55%);
$color-white: #ffffff;
$Light-font-family: 'Neue Haas Grotesk Light';
$Light-font-family-Italic: 'Neue Haas Grotesk Light Italic';
$Medium-font-family: 'Neue Haas Grotesk Medium';
$Medium-font-family-Italic: 'Neue Haas Grotesk Medium Italic';
$Bold-font-family-Italic: 'Neue Haas Grotesk Bold Italic';
$Jennasue: 'Jennasue';
$animationContainerShow: animationContainerShow;
$animationContainerHide: animationContainerHide;
$animationContentHide: animationContentHide;
$animationContentShow: animationContentShow;
$animation-timing-function-hide: 'ease';
$animation-timing-function-show: 'ease';

p,
hr {
  margin: 0;
}
:export {
  duration: $duration;
  contentDuration: $contentDuration;
  colorBackground: $color-background;
}
$maxWidth: 2500;
$maxHeight: 900;
@mixin adaptiv-font($pcSize, $mobSize) {
  $addSize: $pcSize - $mobSize;
  $maxWidth: $maxWidth - 320;
  font-size: calc(
    #{$mobSize + px} + #{$addSize} * ((100vw - 320px) / #{$maxWidth})
  );
}
@mixin adaptiv-font-height($pcSize, $mobSize) {
  $addSize: $pcSize - $mobSize;
  $maxHeight: $maxHeight - 320;
  font-size: calc(
    #{$mobSize + px} + #{$addSize} * ((100vw - 320px) / #{$maxHeight})
  );
}
body {
  cursor: none;
  background-color: $color-background;
}
