@font-face {
    font-family: "Neue Haas Grotesk Light";
    src: url(../assets/fonts/NeueHaasDisplayLight.ttf);
}
@font-face {
    font-family: "Neue Haas Grotesk Light Italic";
    src: url(../assets/fonts/NeueHaasDisplayLightItalic.ttf);
}
@font-face {
    font-family: "Neue Haas Grotesk Medium";
    src: url(../assets/fonts/NeueHaasDisplayMediu.ttf);
}
@font-face {
    font-family: "Neue Haas Grotesk Medium Italic";
    src: url(../assets/fonts/NeueHaasDisplayMediumItalic.ttf);
}
@font-face {
    font-family: "Neue Haas Grotesk Bold Italic";
    src: url(../assets/fonts/NeueHaasDisplayBoldItalic.ttf);
}
@font-face {
    font-family: "Jennasue";
    src: url(../assets/fonts/JENNASUE.TTF);
}