@import '../../style/global.module.scss';
.container {
  padding: 0px 70px 0 70px;
  position: fixed;
  top: 0px;
  right: 0px;
  left: 0px;
  z-index: 99;
  transition: all 1s ease;
  padding: 30px 120px 30px 120px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  .innerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .background {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      z-index: -1;
    }
  }
}
.box {
  display: flex;
  gap: 40px;
  transition: all 1s ease;
}
.logo {
  transition: all 1s ease;
  cursor: pointer;
}
.label {
  font-family: $Medium-font-family;
  color: $color-white;
  @include adaptiv-font(20, 16);
  font-weight: 600;
  white-space: nowrap;
}
@media (max-width: 811px) {
  .label {
    margin-left: 8px;
  }
  .box {
    gap: 20px;
  }
  .container {
    .firstSlide {
      .logo {
        transform: scale(0.2);
      }
    }
  }
}
@media (max-width: 712px) {
  .label {
    margin-left: 8px;
    display: flex;
  }
  .box {
    gap: 5px;
  }
  .container {
    justify-content: space-evenly;
    padding: 60px 15px 0 15px;
    &.firstSlide {
      padding: 30px 50px 30px 30px;
    }
  }
}
@media (max-width: 420px) {
  .label {
    margin-left: 2px;
  }
  .box {
    gap: 6px;
  }
  .logo {
    transform: scale(0.8);
  }
  .container {
    justify-content: space-evenly;
    padding: 60px 15px 0 6px;
    &.firstSlide {
      padding: 60px 20px 0 6px;
      .innerContainer {
        justify-content: start;
      }
      .box {
        gap: 2px;
        margin-left: 20px;
      }
      .logo {
        transform: scale(0.8);
      }
      .label {
        font-size: 12px;
        margin-left: 8px;
      }
    }
  }
}
