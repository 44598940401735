@import 'style/global.module.scss';
.container {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  .content {
    width: 100vw;
    height: 100vh;
    top: 100vh;
    display: flex;
    flex-direction: column;
    // justify-content: space-evenly;
    // background: $orange-gradient;
    .header {
      font-family: $Medium-font-family;
      color: $color-white;
      @include adaptiv-font(70, 40);
      font-weight: 500;
      padding: 100px 70px 50px 70px;
    }
    .boxProjects {
    display: flex;
    overflow: visible;
    position: relative;
    z-index: 0;
    animation: slide-animation 80s  linear infinite;
    // &::-webkit-scrollbar {
    //   display: none;
    // }
    &:hover{
      animation-play-state: paused;
    }
  }
  }
 
}


@media(max-width: 800px){
  .header{
    display: flex;
    justify-content: center;
    padding: 150px 0 50px 0;
  }
}
@media(max-height: 815px){
  .header{
    padding: 50px 0px 50px 0px;
  }
}
@media(max-height: 370px){
  .header{
    padding: 10px 0px 0px 0px;
  }

}
@keyframes slide-animation {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-800px * 7));
  }
}
