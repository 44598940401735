@import 'style/global.module.scss';
.container {
  position: absolute;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: 1;

  .innerContainer {
    // position: absolute;
    z-index: 0;
    background-color: $color-background;
    height: 100vh;
    width: 100vw;
    opacity: 0;
  }
  .content {
    padding: 157px 70px 0 70px;
    height: 100vh;

    .boxText {
      display: flex;
      flex-direction: row;
      gap: 131px;
      padding-bottom: 50px;
      .boldText {
        flex: 0 1 50%;
        color: $color-white;
        @include adaptiv-font(40, 15);
        font-weight: 600;
        font-family: $Medium-font-family-Italic;
        line-height: 140%;
      }
      .text {
        flex: 0 1 50%;
        color: $color-white;
        @include adaptiv-font(30, 13);
        line-height: 140%;
        font-family: $Light-font-family;
      }
    }
    .buttonBox {
      display: flex;
      justify-content: flex-end;
   
    }
    .button {
      padding: 17px 40px 17px 40px;
      font-size: 24px;
      font-weight: 500;
      border-radius: 0;
      border: 0;
      cursor: pointer;
      background-color:$color-orange;
      color:rgb(255, 255, 255);
      margin-bottom: 20px;
    }
  }


  &.show {
    .innerContainer {
      animation-name: showInnerContainer;
      animation-duration: #{$duration}s;
      animation-timing-function: $animation-timing-function-show;
      animation-fill-mode: forwards;
    }
    .content {
      animation-duration: 2s;
      animation-timing-function: $animation-timing-function-show;
      animation-name: showInnerContainer;
      animation-fill-mode: forwards;
    }
  }

}
@media (max-width: 1920px) {
  .container {
    .content {
      padding: 0px 70px 0 70px;
      .boxText {
        gap: 30px;
      }
    }
  }
}
@media (max-width: 570px) {
  .container {
    .content {
      padding: 0 15px 50px 15px;
      .boxText {
        gap: 30px;
        display: flex;
        flex-direction: column;
      }
    }
  }
}
@keyframes showInnerContainer {
  from {
    opacity: 0;
    padding-top: 800px;
  }
  to {
    opacity: 1;
    padding-top: 157px;
  }
}
@keyframes showHideContent {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

